import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { loginCallbackRoute, logoutCallbackRoute } from '@twigeducation/oidc-client-react';
import NotFoundRoute from './pages/NotFound/Route';
import integrationsRoute from './pages/Integrations/routes';
import dashboardRoute from './pages/Dashboard/routes';
import accountsRoutes from './pages/Accounts/routes';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';
import { LayoutRouteContent, LayoutRouteFooter, LayoutRouteWrapper } from './Routes.style';

const Routes = () => (
    <Switch>
        <Route
            render={() => (
                <>
                    <ScrollToTop />
                    <Navigation>
                        <LayoutRouteWrapper>
                            <LayoutRouteContent>
                                <Switch>
                                    {dashboardRoute}
                                    {accountsRoutes}
                                    {integrationsRoute}
                                    {loginCallbackRoute}
                                    {logoutCallbackRoute}
                                    {NotFoundRoute}
                                </Switch>
                            </LayoutRouteContent>
                            <LayoutRouteFooter>
                                <Footer />
                            </LayoutRouteFooter>
                        </LayoutRouteWrapper>
                    </Navigation>
                </>
            )}
        />
    </Switch>
);

export default Routes;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import { MS_PRODUCT_CODE, MS_URL, K6_URL } from '../../constants';

const WithExternalRedirect = ({ roles, children }) => {
    const subscriptions = useSelector(state => state.subscriptions);
    const userInfo = useSelector(state => state.userInfo);
    let externalRedirectUrl;

    if (!roles.includes(userInfo.role)) {
        if (subscriptions?.length === 1 && subscriptions[0]?.product?.productcode === MS_PRODUCT_CODE) {
            externalRedirectUrl = MS_URL;
        } else {
            externalRedirectUrl = K6_URL;
        }
    }

    return (
        <WithAuthentication roles={roles} RoleErrorComponent={() => window.location.replace(externalRedirectUrl)}>
            {children}
        </WithAuthentication>
    );
};

WithExternalRedirect.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WithExternalRedirect;

export const actions = {
    SET_PRODUCT: 'SET_PRODUCT',
};

const setProduct = products => ({
    type: actions.SET_PRODUCT,
    value: products,
});

export { setProduct };

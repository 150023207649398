import React from 'react';
import { Route } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import AccessErrorPage from '../AccessErrorPage';
import Layout from '../../layout';
import withProductSubscriptions from '../../components/withProductSubscriptions';

const Accounts = withProductSubscriptions(props => (
    <AsyncComponent
        appName="ts-accounts"
        authUrl={window.config.AUTHENTICATION_URL}
        componentName="Accounts"
        unleash={{
            clientId: window.config.UNLEASH_CLIENT_ID,
            url: window.config.UNLEASH_URL,
        }}
        {...props}
    />
));

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER', 'TWIGADMIN'];

const routes = [
    <Route
        path="/accounts/*"
        component={() => <Accounts Layout={Layout} isMiddleSchool ssoClientId={window.config.SSO_CLIENT_ID} />}
        key="accounts"
    />,
    <Route
        path="/classes"
        component={() => (
            <WithAuthentication
                roles={allowedRoles}
                RoleErrorComponent={() => <AccessErrorPage link="/student-dashboard/" />}
            >
                <Accounts Layout={Layout} isMiddleSchool ssoClientId={window.config.SSO_CLIENT_ID} />
            </WithAuthentication>
        )}
        key="classes"
    />,
];

export default routes;

import React from 'react';
import propTypes from 'prop-types';
import { middleSchoolTheme } from '@twigeducation/ts-fe-components';
import useTranslation from '../../hooks/useTranslation';
import PageError from './NotSubscribedError.styled';
import { K6_URL } from '../../constants';

const NotSubscribedError = ({ link }) => {
    const { t } = useTranslation();
    return (
        <PageError
            theme={middleSchoolTheme}
            title={t('not_subscribed_error_page.title', 'Uh oh, you look a bit lost!')}
            body={t(
                'not_subscribed_error_page.message',
                `You don't have access to this product.
            If you have multiple accounts, please check you are logged in with the correct account.`,
            )}
            buttonConfig={{
                label: t('not_subscribed_error_page.button_label', 'Go to my Dashboard'),
                onClick: () => {
                    window.location.href = link;
                },
            }}
        />
    );
};

NotSubscribedError.propTypes = {
    link: propTypes.string,
};

NotSubscribedError.defaultProps = {
    link: K6_URL,
};

export default NotSubscribedError;

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import americanEnglish from '../i18n/English-US/translation.json';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            'en-US': americanEnglish,
        },
        lng: 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
        debug: NODE_ENV !== 'production',
    });

import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getStatus, getStatusIcon } from '../helpers';

export const recentDistrictSyncJobsQuery = gql`
    query recentDistrictSyncJobs($districtId: String!, $numResults: Int!) {
        recentDistrictSyncJobs(districtId: $districtId, numResults: $numResults) {
            id
            initiatedBy
            status
            createdAt
            wasScheduledJob
            __typename
        }
    }
`;

const MessageWrap = styled.p`
    margin-bottom: 2rem;
    img {
        float: left;
        margin-top: 5px;
    }
`;

const CleverSyncStatus = ({ districtId }) => (
    <Query
        query={recentDistrictSyncJobsQuery}
        variables={{
            districtId,
            numResults: 1,
        }}
    >
        {({ data, startPolling, stopPolling, loading }) => {
            if (!data || loading) {
                return null;
            }
            const { recentDistrictSyncJobs } = data;

            const areAnySyncsProcessing = recentDistrictSyncJobs.some(
                sync => sync.status === 'processing' || sync.status === 'pending',
            );

            // Refreshing sync list every 30sec if any job is in processing state
            if (areAnySyncsProcessing) {
                startPolling(30000);
            } else {
                stopPolling();
            }
            const hasSyncJobs = recentDistrictSyncJobs && recentDistrictSyncJobs.length > 0;

            return hasSyncJobs ? (
                <MessageWrap data-testid="message-wrap">
                    {getStatusIcon(recentDistrictSyncJobs[0].status)}
                    {`Latest sync status is ${getStatus(recentDistrictSyncJobs[0].status)}`}
                </MessageWrap>
            ) : (
                ''
            );
        }}
    </Query>
);

CleverSyncStatus.propTypes = {
    districtId: PropTypes.string.isRequired,
};

export default CleverSyncStatus;

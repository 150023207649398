import React from 'react';
import PropTypes from 'prop-types';
import { Translation as I18NextTranslation } from 'react-i18next';
import pkg from '../../../../package.json';

const Translation = ({ translationKey, defaultValue, options, ns, ...props }) => (
    <I18NextTranslation ns={ns} {...props}>
        {t => t(translationKey, defaultValue, options)}
    </I18NextTranslation>
);

Translation.defaultProps = {
    ns: pkg.name,
    options: {},
};

Translation.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    ns: PropTypes.string,
    options: PropTypes.shape(),
    translationKey: PropTypes.string.isRequired,
};

export default Translation;

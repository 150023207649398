import { createStore, combineReducers } from 'redux';
import { reducer as authenticationReducer } from '@twigeducation/oidc-client-react';

import { DEFAULT_LANGUAGE } from '../constants';
import subscriptionsReducers from './reducers/subscriptions';
import featureFlagsReducer from './reducers/featureFlags';
import districtDetailsReducer from './reducers/districtDetails';

const configureStore = () => {
    const appReducers = {
        userInfo: authenticationReducer,
        application: (state = {}) => state,
        subscriptions: subscriptionsReducers,
        featureFlags: featureFlagsReducer,
        district: districtDetailsReducer,
    };

    const initialState = {
        userInfo: {},
        application: {
            id: '',
            defaultLanguage: DEFAULT_LANGUAGE,
        },
        subscriptions: {
            product: {
                tocsApp: '',
            },
        },
        featureFlags: {},
        district: {},
    };

    const store = window.config.ENABLE_REDUX_DEV_TOOLS
        ? createStore(
              combineReducers(appReducers),
              initialState,
              // eslint-disable-next-line no-underscore-dangle
              window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
          )
        : createStore(combineReducers(appReducers), initialState);

    store.registeredReducers = { ...appReducers };

    store.registerNewReducersToStore = (name, newReducers) => {
        store.registeredReducers = {
            ...store.registeredReducers,
            [name]: newReducers,
        };

        store.replaceReducer(combineReducers({ ...store.registeredReducers, [name]: newReducers }));
    };

    return store;
};

export default configureStore();

const filterMenuAccordingToIntegration = (menuItemsAsJson, district, userInfo) => {
    const filteredMenuItemsAsJson = menuItemsAsJson.map(item => {
        if (item.title === 'Manage') {
            if (userInfo.role === 'SCHOOL_ADMIN') {
                if (district.integrationType === 'ONE_ROSTER') {
                    return {
                        ...item,
                        links: item.links.filter(link => link.title === 'Users' || link.title === 'Assessment Reports'),
                    };
                }
                return {
                    ...item,
                    links: item.links.filter(link => link.title === 'Assessment Reports'),
                };
            }
            if (district.integrationType === 'ONE_ROSTER') {
                return {
                    ...item,
                    links: item.links.filter(link => link.title !== 'Integrations'),
                };
            }
            return {
                ...item,
                links: item.links.filter(
                    link =>
                        link.title !== 'Data Imports' && link.title !== 'Users' && link.title !== 'SAML Configuration',
                ),
            };
        }
        return item;
    });
    return filteredMenuItemsAsJson.filter(item => item !== null);
};

export default filterMenuAccordingToIntegration;

import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { setProduct } from '../../store/actions/subscriptions';
import query from './Subscriptions.query';

const Subscriptions = ({ store, children }) => {
    const state = store.getState();
    const { userInfo } = state;

    if (!userInfo) {
        return null;
    }

    const { data, error } = useQuery(query, {
        skip: !userInfo?.id,
        variables: {
            userId: userInfo.id,
            filterParams: {
                applyGradeFiltering: false,
            },
        },
    });

    useEffect(() => {
        if (data) {
            store.dispatch(setProduct(data.subscriptions));
        }
    }, [data]);

    return (
        <>
            {error && <div style={{ visibility: 'hidden', height: '0px', width: '0px' }}>{error.message}</div>}
            {children}
        </>
    );
};

Subscriptions.propTypes = {
    store: PropTypes.shape({
        dispatch: PropTypes.func.isRequired,
        getState: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Subscriptions;

export const actions = {
    SET_DISTRICT_DETAILS: 'SET_DISTRICT_DETAILS',
};

const setDistrictDetails = payload => ({
    type: actions.SET_DISTRICT_DETAILS,
    payload,
});

export { setDistrictDetails };

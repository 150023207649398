import React from 'react';
import PropTypes from 'prop-types';
import { AccessErrorPage as AccessErrorPageExternal } from '@twigeducation/allsorts';
import useTranslation from '../../hooks/useTranslation';

const AccessErrorPage = ({ link }) => {
    const { t } = useTranslation();
    return (
        <AccessErrorPageExternal
            link={link}
            buttonLabel={t('access_error_page.button_label', 'Go to my Dashboard')}
            message={t(
                'access_error_page.message',
                `You don't have access to this page. If you have multiple accounts, 
please check you are logged in with the correct account.`,
            )}
            title={t('access_error_page.title', 'Uh oh, you look a bit lost!')}
        />
    );
};

AccessErrorPage.propTypes = {
    link: PropTypes.string.isRequired,
};

export default AccessErrorPage;

import { gql } from '@apollo/client';

const districtDetailsQuery = gql`
    query districtDetails {
        currentUser {
            __typename
            ... on TSDistrictOwnerUser {
                id
                firstName
                lastName
                district {
                    id
                    name
                    integrationType
                    rosterAllowed
                    currentSchoolYear
                    rosterFormat
                    shortCode
                }
            }
            ... on TSSchoolAdminUser {
                id
                firstName
                lastName
                district {
                    id
                    name
                    integrationType
                    rosterAllowed
                    currentSchoolYear
                    rosterFormat
                    shortCode
                }
            }
            ... on TSStudentUser {
                id
                firstName
                lastName
            }
            ... on TSTeacherUser {
                id
                firstName
                lastName
            }
        }
    }
`;

export default districtDetailsQuery;

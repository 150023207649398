import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import {
    elementScrollIntoViewPolyfill,
    elementScrollToPolyfill,
    windowScrollToPolyfill,
} from 'seamless-scroll-polyfill';
import { AuthenticationProvider } from '@twigeducation/oidc-client-react';
import { Provider, connect } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { LoadManifests } from '@twigeducation/async-component';
import { ThemeProvider } from 'styled-components';
import { middleSchoolTheme, GlobalStyle, PageError } from '@twigeducation/ts-fe-components';
import { UnleashConsumer } from '@twigeducation/unleash-client-react';
import { setFeatureFlags } from './store/actions/featureFlags';
import CustomUnleash from './components/Unleash';
import App from './App';
import history from './history';
import Routes from './Routes';
import userManager from './Authentication/userManager';
import store from './store/store';
import Subscriptions from './components/Subscriptions';
import ErrorBoundary from './components/ErrorBoundary';
import client from './apolloClient';
import './microExport';
import './i18n';
import GlobalFonts from './GlobalFonts';
import 'react-toastify/dist/ReactToastify.css';

elementScrollIntoViewPolyfill();
elementScrollToPolyfill();
windowScrollToPolyfill();

window.app = new App();

if (
    window.config.SENTRY_ENABLED === true &&
    typeof window.config.SENTRY_PUBLIC_DSN === 'string' &&
    window.config.SENTRY_PUBLIC_DSN !== ''
) {
    const getSentryTracePropagationTargets = () => {
        const defaultTargets = ['localhost', /^\//];
        if (window.config.SENTRY_TRACE_PROPAGATION_TARGETS) {
            const additionalTargets = window.config.SENTRY_TRACE_PROPAGATION_TARGETS.split(',');
            return defaultTargets.concat(additionalTargets);
        }
        return defaultTargets;
    };

    const dsnFromFeature = ({ getEvent }) => {
        const event = getEvent();
        switch (event?.tags?.mfe) {
            case 'ts-accounts':
                return ['https://f04813f27bbd4930bf0f7101e0467111@o11481.ingest.sentry.io/1423030'];
            case 'ms-footer-mfe':
                return ['https://7cd9b58e73c24614b3b8375fe772ddbe@o11481.ingest.sentry.io/4505510090440704'];
            case 'ms-navbar-mfe':
                return ['https://b817a2324a2e45219d3fbf38948c48fd@o11481.ingest.sentry.io/4505209959546880'];
            case 'ts-integrations':
                return ['https://4d9f931e8fb34957a5bae69d4dc29ab9@o11481.ingest.sentry.io/4505510100598784'];
            default:
        }
        return [];
    };

    Sentry.init({
        dsn: window.config.SENTRY_PUBLIC_DSN,
        transport: Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, dsnFromFeature),
        release: window.config.IMAGE_GIT_COMMIT_SHA,
        environment: window.config.SENTRY_ENVIRONMENT,
        tracesSampleRate: window.config.SENTRY_SAMPLE_RATE,
        attachStackTrace: true,
        normalizeDepth: 10,
        integrations: [
            new Sentry.Replay({
                maskAllInputs: true,
            }),
            new Sentry.BrowserTracing({
                tracePropagationTargets: getSentryTracePropagationTargets(),
                // eslint-disable-next-line new-cap
                routingInstrumentation: new Sentry.reactRouterV5Instrumentation(history),
            }),
        ],
    });
}

const ContentComponent = connect(null, { setFeatureFlags })(props => (
    <ThemeProvider theme={middleSchoolTheme}>
        <GlobalStyle />
        <GlobalFonts />
        <CustomUnleash>
            <UnleashConsumer>
                {featureFlags => {
                    props.setFeatureFlags(featureFlags);
                    return (
                        <>
                            <Suspense fallback={<p>Loading...</p>}>
                                <ApolloProvider client={client}>
                                    <Subscriptions store={store}>
                                        <LoadManifests manifestServiceUrl={window.config.MANIFEST_SERVICE_URL}>
                                            <Router history={history}>
                                                <Routes />
                                            </Router>
                                        </LoadManifests>
                                    </Subscriptions>
                                </ApolloProvider>
                            </Suspense>
                        </>
                    );
                }}
            </UnleashConsumer>
        </CustomUnleash>
    </ThemeProvider>
));

const RootLevelError = () => (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
        <PageError
            buttonConfig={{
                label: 'Go to my Dashboard',
                href: '/',
            }}
            message="We're sorry about that, we'll try to stop it from happening again"
            title="Oops! Something went wrong!"
        />
    </div>
);

const MainComponent = Sentry.withProfiler(
    ErrorBoundary(
        'Top level page error',
        [],
        RootLevelError,
    )(() => (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <AuthenticationProvider userManager={userManager} store={store}>
                    <ContentComponent />
                </AuthenticationProvider>
            </Provider>
        </ApolloProvider>
    )),
);

ReactDOM.render(<MainComponent />, document.getElementById('app'));

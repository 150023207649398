import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../layout';
import Dashboard from '.';
import { DISTRICT_OWNER, SCHOOL_ADMIN } from '../../constants';
import WithExternalRedirect from '../../components/withExternalRedirect';

const allowedRoles = [DISTRICT_OWNER, SCHOOL_ADMIN];

const routes = [
    <Route
        path="/"
        exact
        component={() => (
            <WithExternalRedirect roles={allowedRoles}>
                <Dashboard Layout={Layout} />
            </WithExternalRedirect>
        )}
        key="dashboard"
    />,
];

export default routes;

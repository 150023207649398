import { gql } from '@apollo/client';

const productSubscriptionsQuery = gql`
    query getUserProductSubscriptions($userId: String!, $filterParams: FilterParams!) {
        subscriptions: getUserProductSubscriptions(userId: $userId, filterParams: $filterParams) {
            product {
                productCode
                url
            }
        }
    }
`;

export default productSubscriptionsQuery;

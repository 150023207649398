import PropTypes from 'prop-types';
import React from 'react';

const ImportIcon = ({ color, width, height }) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 32 32"
        preserveAspectRatio="xMidYMid meet"
        style={{ margin: '7px 0 0 0' }}
    >
        <g
            transform="translate(12.000000, 12.000000)
             rotate(90.000000) translate(-12.000000, -12.000000) translate(-0.000000, 0.333333)"
            fill={color}
            fillRule="nonzero"
        >
            <path
                d="M21.3333333,8.85235939e-13 L2.66666667,8.85235939e-13 C1.18666667,8.85235939e-13 0,1.3125
                 0,2.91666667 L0,20.4166667 C0,22.0208333 1.18666667,23.3333333 2.66666667,23.3333333
                 L8,23.3333333 L8,20.4166667 L2.66666667,20.4166667 L2.66666667,5.83333333 L21.3333333,5.83333333
                  L21.3333333,20.4166667 L16,20.4166667 L16,23.3333333 L21.3333333,23.3333333 C22.8,23.3333333
                  24,22.0208333 24,20.4166667 L24,2.91666667 C24,1.3125 22.8133333,8.85235939e-13
                   21.3333333,8.85235939e-13
                  Z M12.5,8.88888889 L7,14.6666667 L11.125,14.6666667 L11.125,23.3333333 L13.875,23.3333333
                   L13.875,14.6666667 L18,14.6666667 L12.5,8.88888889 Z"
            />
        </g>
    </svg>
);

ImportIcon.defaultProps = {
    color: '#ffffff',
    width: '28px',
    height: '28px',
};

ImportIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ImportIcon;

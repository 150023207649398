import React from 'react';
import { Button } from '@twigeducation/ts-fe-components';
import { Assessment, MicrosoftTeams, Squares } from '@twigeducation/ts-fe-components/components/Icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ImportIcon from './Icons/ImportIcon';

export const Container = styled.div`
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;

    a {
        text-align: left;
        font-size: 1.5em;
        padding: 2em 1em;
        min-width: 328px;
        margin-right: 40px;
        width: 23.25rem;
        margin-bottom: 40px;

        svg {
            float: left;
            margin-top: 8px;
        }

        &.dark-blue {
            background: #085172;
        }
        &.light-blue {
            background: #1b88b3;
        }
        &.orange-button {
            background: #f6a622;

            svg {
                margin-top: 5px;
            }
        }
    }
`;
export const SubTitle = styled.div`
    font-size: 0.6em;
`;

export const ButtonTextWrap = styled.div`
    display: inline-block;
`;

const AssessmentReports = (
    <Button
        alternatePrimary
        data-at={null}
        disabled={null}
        href="https://a.twigscience.com/teacher/reports"
        onClickCapture={null}
        tabIndex={null}
        iconLeft
        key="li-assessment-reports"
    >
        <Assessment size={40} />
        <ButtonTextWrap>
            Assessment Reports
            <SubTitle>Real time performance</SubTitle>
        </ButtonTextWrap>
    </Button>
);

const Users = (
    <Button
        className="dark-blue"
        primary
        data-at={null}
        disabled={null}
        href="integrations/users"
        onClickCapture={null}
        tabIndex={null}
        iconLeft
        key="li-users"
    >
        <MicrosoftTeams size={40} color="white" />
        <ButtonTextWrap>
            Users
            <SubTitle>Enrollments</SubTitle>
        </ButtonTextWrap>
    </Button>
);
const DataImports = (
    <Button
        className="light-blue"
        primary
        data-at={null}
        disabled={null}
        href="integrations/data-imports"
        onClickCapture={null}
        tabIndex={null}
        iconLeft
        key="li-data-imports"
    >
        <ImportIcon width="40" height="40" className="data-imports-icon" />
        <ButtonTextWrap>
            Data Imports
            <SubTitle>Roster status</SubTitle>
        </ButtonTextWrap>
    </Button>
);
const Integrations = (
    <Button
        className="light-blue"
        primary
        data-at={null}
        disabled={null}
        href="integrations/"
        onClickCapture={null}
        tabIndex={null}
        iconLeft
        key="li-integrations"
    >
        <ImportIcon width="40" height="40" className="data-imports-icon" />
        <ButtonTextWrap>
            Integrations
            <SubTitle>Sync status</SubTitle>
        </ButtonTextWrap>
    </Button>
);
const Saml = (
    <Button
        className="orange-button"
        data-at={null}
        disabled={null}
        href="integrations/saml"
        onClickCapture={null}
        tabIndex={null}
        iconLeft
        key="li-saml"
    >
        <Squares size={40} color="white" />
        <ButtonTextWrap>
            SAML Configuration
            <SubTitle>Set up</SubTitle>
        </ButtonTextWrap>
    </Button>
);

const TilesObj = {
    AssessmentReports,
    Users,
    DataImports,
    Integrations,
    Saml,
};

const districtOwnerKeysOneRoster = ['AssessmentReports', 'Users', 'DataImports', 'Saml'];
const schoolAdminKeysOneRoster = ['AssessmentReports', 'Users'];

const districtOwnerKeysCleverClasslink = ['AssessmentReports', 'Integrations'];
const schoolAdminKeysCleverClasslink = ['AssessmentReports'];

const Tiles = ({ integrationType, role }) => (
    <Container>
        {Object.keys(TilesObj).map(key => {
            if (role === 'DISTRICT_OWNER' && integrationType === 'ONE_ROSTER') {
                return districtOwnerKeysOneRoster.includes(key) && TilesObj[key];
            }
            if (role === 'SCHOOL_ADMIN' && integrationType === 'ONE_ROSTER') {
                return schoolAdminKeysOneRoster.includes(key) && TilesObj[key];
            }
            if (role === 'DISTRICT_OWNER' && integrationType !== 'ONE_ROSTER') {
                return districtOwnerKeysCleverClasslink.includes(key) && TilesObj[key];
            }
            if (role === 'SCHOOL_ADMIN' && integrationType !== 'ONE_ROSTER') {
                return schoolAdminKeysCleverClasslink.includes(key) && TilesObj[key];
            }
            return null;
        })}
    </Container>
);

Tiles.propTypes = {
    integrationType: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
};

export default Tiles;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { RedirectToLogin } from '@twigeducation/oidc-client-react';
import { Header, middleSchoolTheme } from '@twigeducation/ts-fe-components';
import styled from 'styled-components';

import districtDetailsQuery from '../../UserDetails.query';

import Tiles from './Tiles';
import CleverSyncStatus from './Clever/CleverSyncStatus';
import ClassLinkSyncPanel from './ClassLink/ClassLinkSyncStatus';

const Wrapper = styled.div``;
export const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0;

    @media screen and (max-width: 1200px) {
        padding: 2rem 2rem;
    }
`;
export const SubTitle = styled.div`
    font-size: 0.6em;
`;

export const ButtonTextWrap = styled.div`
    display: inline-block;
`;
const HeaderSubTitle = styled.div`
    &:before {
        content: ' ';
        background-color: ${props => props.theme.primaryColor};
        border-radius: 10px;
        display: block;
        height: 6px;
        position: relative;
        top: 1rem;
        width: 31px;
        margin-bottom: 1.5rem;
        margin-top: -2.5rem;
    }
`;

const Dashboard = ({ integrationType }) => {
    const userInfo = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    if (!userInfo.id) return <RedirectToLogin />;

    const { data, error, loading } = useQuery(districtDetailsQuery, {
        skip: !userInfo,
    });

    if (data?.currentUser?.district) {
        dispatch({ type: 'SET_DISTRICT_DETAILS', payload: data.currentUser.district });
    }

    if (error) return <p>{`Error! ${error.message}`}</p>;
    if (loading) return <p>Loading...</p>;

    return (
        <Wrapper>
            <Header title="Admin Dashboard" backgroundColor={middleSchoolTheme.primaryColor}>
                <HeaderSubTitle>
                    <br />
                    Quick access
                </HeaderSubTitle>
            </Header>
            <Container>
                {data?.currentUser?.district?.id && <CleverSyncStatus districtId={data?.currentUser?.district?.id} />}
                {data?.currentUser?.district?.id && <ClassLinkSyncPanel districtId={data?.currentUser?.district?.id} />}
                {(integrationType || data?.currentUser?.district?.integrationType) && userInfo.role && (
                    <Tiles
                        integrationType={integrationType || data?.currentUser?.district?.integrationType}
                        role={userInfo.role}
                    />
                )}
            </Container>
        </Wrapper>
    );
};

Dashboard.defaultProps = {
    integrationType: '',
};

Dashboard.propTypes = {
    integrationType: PropTypes.string,
};

export default Dashboard;

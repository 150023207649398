import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import districtOwnerMenu from './districtOwnerMenu.json';
import districtOwnerMenuEs from './districtOwnerMenu-es.json';
import filterMenuAccordingToIntegration from '../../helpers/filterMenu';
import districtDetailsQuery from '../../UserDetails.query';

const Navbar = state => {
    const { userInfo, jsonMenu } = state;
    const { data, loading } = useQuery(districtDetailsQuery, {
        skip: !(userInfo && userInfo.id),
    });
    const props = { ...state };
    const district = !loading && data?.currentUser?.district ? data.currentUser.district : '';
    props.menuItemsAsJson = filterMenuAccordingToIntegration(jsonMenu, district, userInfo);
    return <AsyncComponent appName="ms-navbar-mfe" componentName="Navbar" {...props} />;
};

const mapStateToProps = state => {
    const language = state?.product?.ietfTag || 'en-US';
    const jsonMenu = language === 'es-US' ? districtOwnerMenuEs : districtOwnerMenu;
    return { userInfo: state.userInfo, jsonMenu, hostApp: 'admin' };
};
export default withSignoutRedirect(() => {})(connect(mapStateToProps)(Navbar));

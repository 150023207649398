import React from 'react';
import styled from 'styled-components';
import SyncPendingIcon from './Icons/sync-pending-icon.svg';
import SyncSuccessIcon from './Icons/sync-success-icon.svg';
import SyncFailedIcon from './Icons/sync-failed-icon.svg';

const Icon = styled.img`
    display: inline;
    margin-right: 0.5em;
`;

export const getStatus = status => {
    const statusComponents = {
        pending: 'pending',
        processing: 'processing',
        completed: 'successful',
        errored: 'failed',
        aborted: 'aborted',
        unchanged: 'successful (no changes)',
    };
    return statusComponents[status] || status;
};
export const getStatusIcon = status => {
    const statusComponents = {
        pending: <Icon src={SyncPendingIcon} />,
        processing: <Icon src={SyncPendingIcon} />,
        completed: <Icon src={SyncSuccessIcon} />,
        errored: <Icon src={SyncFailedIcon} />,
        aborted: <Icon src={SyncFailedIcon} />,
        unchanged: <Icon src={SyncSuccessIcon} />,
    };
    return statusComponents[status] || status;
};

export default { getStatus, getStatusIcon };

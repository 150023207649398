import React from 'react';
import { useSelector } from 'react-redux';
import NotSubscribedError from '../../pages/NotSubscribedError';

const WithProductSubscriptions = Component => {
    const withProductSubscriptionsComponent = props => {
        const subscriptions = useSelector(state => state.subscriptions);

        if (!subscriptions?.product || subscriptions?.product?.length === 0) {
            return <NotSubscribedError />;
        }

        return <Component {...props} />;
    };

    return withProductSubscriptionsComponent;
};

export default WithProductSubscriptions;

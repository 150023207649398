import React from 'react';
import ReactDom from 'react-dom';
import * as ApolloClient from '@apollo/client';
import * as ApolloClientComponents from '@apollo/client/react/components';
import * as ApolloClientHOC from '@apollo/client/react/hoc';
import * as FormsyReact from 'formsy-react';
import * as RavenLogger from '@twigeducation/raven-logger';
import * as ReactInterchange from '@twigeducation/react-interchange';
import * as ReactLazyLoad from 'react-lazyload';
import * as ReactRedux from 'react-redux';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactRouter from 'react-router';
import * as Redux from 'redux';
import * as StyledComponents from 'styled-components';
import * as TsFeComponents from '@twigeducation/ts-fe-components';
import * as Unleash from '@twigeducation/unleash-client-react';
import * as ReactI18Next from 'react-i18next';
import * as AsyncComponent from '@twigeducation/async-component';
import * as ReactCloudinaryImage from '@twigeducation/react-cloudinary-image';
import * as ReactErrorBoundary from '@twigeducation/react-error-boundary';
import * as lodash from 'lodash';
import * as ReactGA4 from 'react-ga4';

window.ApolloClient = ApolloClient;
window.ApolloClientComponents = ApolloClientComponents;
window.ApolloClientHOC = ApolloClientHOC;
window.FormsyReact = FormsyReact;
window.RavenLogger = RavenLogger;
window.ReactInterchange = ReactInterchange;
window.react = React;
window.ReactDom = ReactDom;
window.ReactLazyLoad = ReactLazyLoad;
window.ReactRedux = ReactRedux;
window.ReactRouter = ReactRouter;
window.ReactRouterDom = ReactRouterDom;
window.Redux = Redux;
window.StyledComponents = StyledComponents;
window.TsFeComponents = TsFeComponents;
window.Unleash = Unleash;
window.ReactI18Next = ReactI18Next;
window.AsyncComponent = AsyncComponent;
window.ReactCloudinaryImage = ReactCloudinaryImage;
window.ReactErrorBoundary = ReactErrorBoundary;
window.lodash = lodash;
window.ReactGA4 = ReactGA4;
